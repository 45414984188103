import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { Button, Image, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import profile from "../../../images/logo.png";
import firebaseConfig from "../../../firebaseConfig.json";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "../../components/Pagination";
import { capitalFirstLetter } from "../../../utils/string";

function Partners() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(searchParams.get("search") ?? "");
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: "",
      description: "",
      address: "",
      profile_picture: "",
      status: "active",
    },
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [partners, setPartners] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const fetchPartners = async () => {
    setIsLoading(true);
    axiosInstance
      .get(`partners/all?page=${page}&search=${search}`)
      .then((resp) => {
        if (page === "all") {
          setPartners(resp.data.data);
        } else {
          setPartners(resp.data.data.data);
          setData(resp.data.data);
        }
        setIsLoading(false);
      });
  };

  const profilePicture = watch("profile_picture");

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const img = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, img);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      setValue("profile_picture", filename);
    });
  };

  useEffect(() => {
    if (!searchParams.get("page") || !searchParams.get("search")) {
      setSearchParams({ ...searchParams, page: 1 });
      setpage(1);
    }

    fetchPartners();
  }, [page, search]);

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  const save = async (data) => {
    if (action === "create") {
      axiosInstance.post("partners/create", { ...data }).then((resp) => {
        toast.success("Partner created succesfully");
        fetchPartners();
        setValue("address", "");
        setValue("description", "");
        setValue("profile_picture", "");
        setValue("name", "");
        setModalOpen(false);
      });
    } else {
      axiosInstance.put(`partners/edit/${id}`, { ...data }).then((resp) => {
        toast.success("Partner updated succesfully");
        fetchPartners();
        setValue("address", "");
        setValue("description", "");
        setValue("profile_picture", "");
        setValue("name", "");
        setModalOpen(false);
      });
    }
  };

  const createPartner = () => {
    setAction("create");
    setModalOpen(true);
  };

  const editPartner = (data) => {
    setAction("edit");
    setValue("address", data.address);
    setValue("description", data.description);
    setValue("profile_picture", data.profile_picture);
    setValue("name", data.name);
    setId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setValue("address", "");
    setValue("description", "");
    setValue("profile_picture", "");
    setValue("name", "");
    setModalOpen(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-auto"
              onClick={() => createPartner()}
            >
              + New Partner
            </button>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => {
                  setSearch(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    search: e.target.value,
                  });
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive rounded card-table">
            <div id="partners" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th></th>
                    <th>Partner Name</th>
                    <th>Descriptions</th>
                    <th>Address</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    partners.map((partner, partnerId) => {
                      return (
                        <tr
                          key={partnerId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td width={60}>
                            <Image
                              className="border rounded"
                              src={partner.profile_picture ?? profile}
                              width={60}
                              alt={partner.name}
                            />{" "}
                          </td>
                          <td>{partner.name}</td>
                          <td>{partner.description}</td>
                          <td>{partner.address}</td>
                          <td width={100}>
                            <span
                              className={
                                partner.status === "active"
                                  ? "badge bg-success"
                                  : "badge bg-danger"
                              }
                            >
                              {capitalFirstLetter(partner.status)}
                            </span>
                          </td>
                          <td width={130}>
                            {moment(partner.created_at).format("DD MMM YYYY")}
                          </td>
                          <td width={40}>
                            <button
                              className="btn btn-primary"
                              style={{ padding: "8px 14px" }}
                              onClick={() => {
                                editPartner(partner);
                              }}
                            >
                              <i className="flaticon-381-edit-1 text-white" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}

                  {partners.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={7} className="text-center">
                        No partner yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {data && page !== "all" && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={data.total <= 10 ? 1 : Math.ceil(data.total / 10)}
                  onPageChange={handlePageChange}
                  totalData={data.total}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={modalOpen} onHide={setModalOpen} centered>
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add New Partner" : "Edit Partner"}
            </Modal.Title>
            <Button onClick={() => closeModal()} variant="" className="close">
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name</label>
              <input
                {...register("name", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                {...register("description", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                {...register("address", { required: true })}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Logo</label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={handleUpload}
                  />
                  <label className="custom-file-label">
                    {profilePicture
                      ? profilePicture.substring(0, 36) + "..."
                      : "Choose file"}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select {...register("status")} className="form-control">
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => closeModal()} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Partners;
