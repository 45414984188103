import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Dropdown, Image, Modal, Spinner } from "react-bootstrap";
import { formTypes } from "../../../vars/formTypes";
import { capitalFirstLetter, money } from "../../../utils/string";
import profile from "../../../images/logo.png";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import firebaseConfig from "../../../firebaseConfig.json";

function Jobs() {
  const initialValue = {
    name: "",
    partner_id: 0,
    job_category_id: 0,
    status: "in-progress",
    description: "",
    target: 0,
    partner_fee: 0,
    video_guide: "",
    pdf_guide: "",
    link_guide: "",
    provice_id: 0,
    regency_id: 0,
    district_id: 0,
    village_id: 0,
    restricted_location: 0,
  };
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: initialValue,
  });
  const [id, setId] = useState(0);
  const [action, setAction] = useState("create");
  const [jobs, setJobs] = useState([]);
  const [partners, setPartners] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [regencies, setRegencies] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const [openToggle, setOpenToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setpage] = useState(searchParams.get("page") ?? 1);
  const [search, setSearch] = useState(searchParams.get("search") ?? "");
  const [status, setStatus] = useState(searchParams.get("status") ?? "");

  const pdfGuide = watch("pdf_guide");
  // const regencyId = watch("regency_id");
  const fetchJobs = async () => {
    setIsLoading(true);
    axiosInstance
      .get(`jobs/all?page=${page}&search=${search}&status=${status}`)
      .then((resp) => {
        if (page === "all") {
          setJobs(resp.data.data);
        } else {
          setData(resp.data.data);
          setJobs(resp.data.data.data);
        }
        setIsLoading(false);
      });
  };

  const uploadPDF = async (e) => {
    const file = e.target.files[0];
    const token = "ca5a280d-6a3f-4178-adf4-c2f5b360d491";
    const pdf = uuidv4() + "." + file.name.split(".").reverse()[0];
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, pdf);
    uploadBytes(storageRef, file).then((snapshot) => {
      const fullPath = snapshot.metadata.fullPath;
      const bucket = snapshot.metadata.bucket;
      const filename = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${fullPath}?alt=media&token=${token}`;
      setValue("pdf_guide", filename);
    });
  };

  const fetchPartners = async () => {
    axiosInstance.get("partners/list").then((resp) => {
      setPartners(resp.data.data);
    });
  };

  const fetchJobCategories = async () => {
    axiosInstance.get("job-categories/list").then((resp) => {
      setJobCategories(resp.data.data);
    });
  };

  const fetchProvinces = async () => {
    axiosInstance.get("select/provinces").then((resp) => {
      setProvinces(resp.data.data);
    });
  };
  const fetchRegencies = async (id, isInitial = false) => {
    axiosInstance.get(`select/regencies/${id}`).then((resp) => {
      setRegencies(resp.data.data);
      if (!isInitial) {
        setValue("regency_id", 0);
        setValue("district_id", 0);
        setValue("village_id", 0);
      }
      setDistricts([]);
      setVillages([]);
    });
  };

  const fetchDistricts = async (id, isInitial = false) => {
    axiosInstance.get(`select/districts/${id}`).then((resp) => {
      setDistricts(resp.data.data);
      if (!isInitial) {
        setValue("district_id", 0);
        setValue("village_id", 0);
      }
      setVillages([]);
    });
  };
  const fetchVillages = async (id, isInitial = false) => {
    axiosInstance.get(`select/villages/${id}`).then((resp) => {
      setVillages(resp.data.data);
      if (!isInitial) {
        setValue("village_id", 0);
      }
    });
  };

  const fetchSubscriptions = async () => {
    setIsLoading(true);
    axiosInstance.get("subscriptions/all").then((resp) => {
      const subs = resp.data.data.data;
      let d = [];
      for (let index = 0; index < subs.length; index++) {
        d.push({
          subscription_id: subs[index].id,
          amount: 0,
          name: subs[index].name,
        });
      }
      setSubscriptions(d);
      setIsLoading(false);
    });
  };

  const fetchInitialData = () => {
    fetchPartners();
    fetchSubscriptions();
    fetchJobCategories();
    fetchProvinces();
    fetchJobs();
  };

  useEffect(() => {
    if (
      !searchParams.get("page") ||
      !searchParams.get("search") ||
      !searchParams.get("status")
    ) {
      setSearchParams({ page: 1 });
      setpage(1);
    }
    fetchInitialData();
  }, [page, search, status]);

  const resetForm = () => {
    setValue("partner_id", 0);
    setValue("job_category_id", 0);
    setValue("name", "");
    setValue("description", "");
    setValue("status", "in-progress");
    setValue("target", 0);
    setValue("partner_fee", 0);
    setValue("video_guide", "");
    setValue("link_guide", "");
    setValue("pdf_guide", "");
    resetLocation();
  };

  const resetLocation = () => {
    setValue("province_id", 0);
    setValue("regency_id", 0);
    setValue("district_id", 0);
    setValue("village_id", 0);
    setValue("restricted_location", 0);
    setRegencies([]);
    setDistricts([]);
    setVillages([]);
  };

  const save = async (data) => {
    const postData = {
      ...data,
      job_fields: fields,
      job_rewards: subscriptions,
    };
    if (postData.job_fields.length === 0) {
      toast.error("Job fields is required");
      return;
    }

    if (postData.job_fields.filter((e) => e.label.trim() === "").length > 0) {
      toast.error("Job fields label must not empty");
      return;
    }

    if (
      postData.job_rewards.filter((e) => Number(e.amount) === 0).length ===
      postData.job_rewards.length
    ) {
      toast.error("Job fee must not zero");
      return;
    }

    if (
      postData.job_fields.filter((e) => Boolean(e.is_pinned) === true)
        .length === 0
    ) {
      toast.error("At least 1 field need to be pinned");
      return;
    }

    if (action === "create") {
      axiosInstance.post("jobs/create", postData).then((resp) => {
        toast.success("Job created succesfully");
        fetchInitialData();
        resetForm();
        setModalOpen(false);
      });
    } else {
      axiosInstance.put(`jobs/edit/${id}`, postData).then((resp) => {
        toast.success("Job updated succesfully");
        fetchInitialData();
        resetForm();
        setModalOpen(false);
      });
    }
  };

  const createJob = () => {
    setFields([]);
    fetchSubscriptions();
    setAction("create");
    resetForm();
    setModalOpen(true);
  };

  const editJob = async (data) => {
    setAction("edit");
    setValue("partner_id", data.partner_id);
    setValue("job_category_id", data.job_category_id);
    setValue("name", data.name);
    setValue("description", data.description);
    setValue("status", data.status);
    setValue("target", data.target);
    setValue("partner_fee", data.partner_fee);
    setValue("video_guide", data.video_guide);
    setValue("link_guide", data.link_guide);
    setValue("pdf_guide", data.pdf_guide);
    setSubscriptions(data.job_rewards);
    setFields(data.job_fields);
    setId(data.id);

    if (data.province_id) {
      await fetchRegencies(data.province_id, true);
    }
    if (data.regency_id) {
      await fetchDistricts(data.regency_id, true);
    }
    if (data.district_id) {
      await fetchVillages(data.district_id, true);
    }

    setTimeout(() => {
      setValue("province_id", data.province_id);
      setValue("regency_id", data.regency_id);
      setValue("district_id", data.district_id);
      setValue("village_id", data.village_id);
      setValue("restricted_location", data.restricted_location);
    }, 100);
    setTimeout(() => {
      setModalOpen(true);
    }, 300);
  };

  const addField = () => {
    let newFields = [...fields];
    newFields.push({
      label: "",
      type: "text",
      is_pinned: false,
      note: "",
      options: [],
    });
    setFields(newFields);
  };

  const changeField = (index, f, val) => {
    let newFields = [...fields];
    newFields[index][f] = val;
    setFields(newFields);
  };

  const changePined = (index) => {
    let newFields = [...fields];
    let newestFields = newFields.map((e) => {
      return { ...e, is_pinned: false };
    });
    newestFields[index].is_pinned = true;
    setFields(newestFields);
  };

  const changePrice = (index, f, val) => {
    let newFields = [...subscriptions];
    newFields[index][f] = val;
    setSubscriptions(newFields);
  };

  const deleteField = (index) => {
    let newFields = [...fields];
    if (fields[index].id) {
      axiosInstance
        .delete("job-fields/delete/" + fields[index].id)
        .then((resp) => {
          fetchJobs();
        });
    }
    setFields(newFields.filter((e, i) => index !== i));
  };

  const handlePageChange = (val) => {
    setpage(val);
    setSearchParams({ page: val });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
            <button
              className="btn btn-primary font-w600 mr-4"
              onClick={() => {
                createJob();
              }}
            >
              + New Job
            </button>
            <Dropdown
              show={openToggle}
              onToggle={() => setOpenToggle(!openToggle)}
              className="dropdown mb-2 ml-auto mr-3"
            >
              <Dropdown.Toggle
                className="i-false btn btn-primary btn-rounded light"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setOpenToggle(true);
                }}
              >
                <i className="las la-bolt scale5 mr-3" />
                {status === "" ? "All Status" : capitalFirstLetter(status)}
                <i className="las la-angle-down ml-3" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-center">
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("");
                    setSearchParams({ ...searchParams, search: "" });
                  }}
                >
                  <span className="text-primary">All Status</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("in-progress");
                    setSearchParams({ ...searchParams, search: "in-progress" });
                  }}
                >
                  <span className="text-primary">In Progress</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("done");
                    setSearchParams({ ...searchParams, search: "done" });
                  }}
                >
                  <span className="text-success">Done</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setStatus("deleted");
                    setSearchParams({ ...searchParams, search: "deleted" });
                  }}
                >
                  <span className="text-warning">Deleted</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="input-group search-area d-xl-inline-flex d-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => {
                  setSearch(e.target.value);
                  setSearchParams({ ...searchParams, search: e.target.value });
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="flaticon-381-search-2" />
                </span>
              </div>
            </div>
          </div>

          <div className="table-responsive rounded card-table">
            <div id="jobs" className="dataTables_wrapper no-footer">
              <table
                className="table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer overflow-hidden"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th>Partner</th>
                    <th>Job Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Partner Fee</th>
                    <th>Member Fee</th>
                    <th>Target</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        />
                      </td>
                    </tr>
                  ) : (
                    jobs.map((job, jobId) => {
                      return (
                        <tr
                          key={jobId}
                          className="alert alert-dismissible border-0 odd"
                          role="row"
                        >
                          <td>
                            <Image
                              className="border rounded"
                              src={job.partner_logo ?? profile}
                              width={60}
                              alt={job.partner_name}
                            />
                            <Link
                              to={`/jobs/job-detail?id=${job.id}`}
                              className="ml-2 text-primary"
                            >
                              {job.partner_name}
                            </Link>
                          </td>
                          <td>{job.name}</td>
                          <td>
                            {job.description.substring(0, 30)}
                            {job.description.length > 0 ? " ..." : ""}
                          </td>
                          <td>{job.status}</td>
                          <td>{money(job.partner_fee)}</td>
                          <td>
                            {job.job_rewards.map((f, g) => {
                              return (
                                <p key={g} className="m-0">
                                  {f.name} - {money(f.amount)}
                                </p>
                              );
                            })}
                          </td>
                          <td>
                            {job.approved_job}/{job.target}
                          </td>
                          <td width={40}>
                            <button
                              className="btn btn-primary"
                              style={{ padding: "8px 14px" }}
                              onClick={() => {
                                editJob(job);
                              }}
                            >
                              <i className="flaticon-381-edit-1 text-white" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {jobs.length === 0 && (
                    <tr
                      className="alert alert-dismissible border-0 odd"
                      role="row"
                    >
                      <td colSpan={6} className="text-center">
                        No job yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data && page !== "all" && (
                <Pagination
                  currentPage={data.current_page}
                  totalPages={
                    data.total <= data.per_page
                      ? 1
                      : Math.ceil(data.total / data.per_page)
                  }
                  onPageChange={handlePageChange}
                  totalData={data.total}
                  perPage={data.per_page}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="fade"
        show={modalOpen}
        onHide={setModalOpen}
        centered
        size="lg"
      >
        <form onSubmit={handleSubmit(save)}>
          <Modal.Header className="border-0">
            <Modal.Title>
              {action === "create" ? "Add New Job" : "Edit Job"}
            </Modal.Title>
            <Button
              onClick={() => setModalOpen(false)}
              variant=""
              className="close"
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-5">
                <div className="form-group">
                  <label>Job Name</label>
                  <input
                    {...register("name", { required: true })}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Partner:</label>
                  <select {...register("partner_id")} className="form-control">
                    {partners.map((partner, partnerIndex) => {
                      return (
                        <option key={partnerIndex} value={partner.id}>
                          {partner.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>Job Category:</label>
                  <select
                    {...register("job_category_id")}
                    className="form-control"
                  >
                    {jobCategories.map((category, categoryIndex) => {
                      return (
                        <option key={categoryIndex} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                {...register("description", { required: true })}
                className="form-control"
                rows={4}
                aria-multiline
              />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Partner Fee</label>
                  <input
                    {...register("partner_fee", { required: true })}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Target</label>
                  <input
                    {...register("target", { required: true })}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Status:</label>
                  <select {...register("status")} className="form-control">
                    <option value={"in-progress"}>In Progress</option>
                    <option value={"done"}>Done</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              {subscriptions.map((s, sid) => {
                return (
                  <div className="col-lg-4" key={sid}>
                    <div className="form-group">
                      <label className="mb-0">{s.name} fee</label>
                      <br />
                      <label style={{ fontSize: 12, color: "orange" }}>
                        leave blank or zero to exclude
                      </label>
                      <input
                        className="form-control"
                        value={s.amount}
                        onChange={(e) => {
                          changePrice(sid, "amount", e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Link Guide</label>
                  <input
                    {...register("link_guide", { required: true })}
                    className="form-control"
                    type="text"
                    max="255"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Video Guide</label>
                  <input
                    {...register("video_guide", { required: true })}
                    className="form-control"
                    type="text"
                    max="255"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>PDF Guide</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        onChange={uploadPDF}
                      />
                      <label className="custom-file-label">
                        {pdfGuide
                          ? pdfGuide.substring(0, 22) + "..."
                          : "Choose file"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-lg-12">
                <label className=" mb-3">Job Locations</label>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Province </label>
                  <select
                    {...register("province_id")}
                    className="form-control"
                    onChange={(e) => fetchRegencies(e.target.value)}
                  >
                    <option value={0}>Choose </option>
                    {provinces.map((province, provinceIndex) => {
                      return (
                        <option key={provinceIndex} value={province.id}>
                          {province.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Regency </label>
                  <select
                    {...register("regency_id")}
                    className="form-control"
                    onChange={(e) => fetchDistricts(e.target.value)}
                  >
                    <option value={0}>Choose </option>
                    {regencies.map((regency, regencyIndex) => {
                      return (
                        <option key={regencyIndex} value={regency.id}>
                          {regency.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>District </label>
                  <select
                    {...register("district_id")}
                    className="form-control"
                    onChange={(e) => fetchVillages(e.target.value)}
                  >
                    <option value={0}>Choose </option>
                    {districts.map((district, districtIndex) => {
                      return (
                        <option key={districtIndex} value={district.id}>
                          {district.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group">
                  <label>Village </label>
                  <select {...register("village_id")} className="form-control">
                    <option value={0}>Choose </option>
                    {villages.map((village, villageIndex) => {
                      return (
                        <option key={villageIndex} value={village.id}>
                          {village.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Restricted Location </label>
                  <select
                    {...register("restricted_location")}
                    className="form-control"
                  >
                    <option value={0}>No </option>
                    <option value={1}>Yes </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>Reset Location </label>
                  <br />
                  <button
                    onClick={() => {
                      resetLocation();
                    }}
                    type="button"
                    className="btn btn-warning ml-4"
                  >
                    {" "}
                    Reset{" "}
                  </button>
                </div>
              </div>
            </div>
            <hr />

            <div className="col-lg-12 p-0">
              <label>Job Fields:</label>
            </div>
            {fields.map((f, fid) => {
              return (
                <>
                  <div className="row mt-3" key={fid}>
                    <div className="col-lg-4">
                      <input
                        value={f.label}
                        onChange={(e) => {
                          changeField(fid, "label", e.target.value);
                        }}
                        className="form-control"
                        placeholder="Label..."
                        required={true}
                      />
                    </div>
                    <div className="col-lg-3">
                      <select
                        className="form-control"
                        value={f.type}
                        onChange={(e) => {
                          changeField(fid, "type", e.target.value);
                        }}
                      >
                        {formTypes.map((e, ei) => (
                          <option key={ei} value={e.type}>
                            {e.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <textarea
                        value={f.note}
                        onChange={(e) => {
                          changeField(fid, "note", e.target.value);
                        }}
                        className="form-control"
                        rows={3}
                        aria-multiline
                        placeholder="Note..."
                        required={true}
                      />
                    </div>
                    <div className="col-lg-1 p-0 text-center">
                      <label className="text-center">
                        <p className="mb-0">Pin</p>
                        <input
                          style={{ width: 16, height: 16 }}
                          type="radio"
                          checked={Boolean(f.is_pinned) === true}
                          onChange={(e) => {
                            changePined(fid);
                          }}
                        />
                      </label>
                    </div>
                    <div className="col-lg-1 p-0">
                      <button
                        type="button"
                        className="btn btn-danger btn-outline mt-2"
                        style={{ padding: "8px 14px" }}
                        onClick={() => {
                          deleteField(fid);
                        }}
                      >
                        <i className="flaticon-381-trash-1 text-white" />
                      </button>
                    </div>
                  </div>
                  {f.type === "options" && (
                    <div className="row px-2">
                      {f.options.map((opt, optIndex) => {
                        return (
                          <div
                            style={{
                              maxWidth: "33%",
                              width: "33%",
                              marginTop: 12,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              paddingLeft: 4,
                              paddingRight: 4,
                            }}
                            key={optIndex}
                          >
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                f.options[optIndex] = e.target.value;
                                changeField(fid, "options", f.options);
                              }}
                              value={opt}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-outline"
                              style={{ padding: "8px 14px", marginLeft: 4 }}
                              onClick={() => {
                                let newOp = [...f.options];
                                newOp.splice(optIndex, 1);
                                changeField(fid, "options", newOp);
                              }}
                            >
                              <i className="flaticon-381-trash-1 text-white" />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {f.type === "options" && (
                    <button
                      type="button"
                      onClick={() => {
                        let newOptions = [...f.options];
                        newOptions.push("");
                        changeField(fid, "options", newOptions);
                      }}
                      className="btn btn-outline-success mt-3"
                      style={{ width: "20%", padding: "8px" }}
                    >
                      <i className="flaticon-381-add-2" /> Add Option
                    </button>
                  )}
                </>
              );
            })}
            <button
              type="button"
              onClick={() => {
                addField();
              }}
              className="btn btn-outline-success mt-3"
              style={{ width: "100%", padding: "8px" }}
            >
              <i className="flaticon-381-add-2" /> Add Field
            </button>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button onClick={() => setModalOpen(false)} variant="danger light">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Jobs;
